import { IServerErrorResponse } from '../components/custom Sign In/types';

export const serverErrorsHandler = (
  error: IServerErrorResponse | string | number,
): string => {
  let errorCode: string;
  if (typeof error === 'object') {
    const serverErrorCode =
      error?.details?.errorcode || error?.details?.errorCode;
    errorCode = (serverErrorCode || 'SMForm_Error_General_Err') as string;
  } else {
    errorCode = error as string;
  }
  errorCode = errorCode.toString().replace('-', '');
  return errorCode.includes('SMForm_Error_')
    ? errorCode
    : `SMForm_Error_${errorCode}`;
};

export const INTERACTIONS = {
  LOGIN_SUBMIT: 'login-submit',
  CLOSING_WINDOW: 'closing-window',
  CLICK_FORGOT_PASSWORD: 'click-forgot-password',
  CLICK_SIGNUP_LINK: 'click-signup-link',
  CLICK_SOCIAL: 'click-social',
};
