import { TFunction } from 'i18next';

const PASS_MIN_LEN = 4;
const keys = {
  password: {
    required: 'SMForm_Error_Password_Blank',
    ascii: 'SMForm_Error_Non_Ascii_Chars',
    length: 'SMForm_Error_Password_Length_Login',
  },
  email: {
    required: 'SMForm_Error_Email_Blank',
    format: 'SMForm_Error_Email_Invalid',
  },
};

const isValidEmail = (emailToTest: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(emailToTest);
};

const isAscii = (char: string) => char.charCodeAt(0) < 127;
const isAsciiOnlyInput = (value: string) => Array.from(value).every(isAscii);

export const validateSiteMembersPassword = (
  value: string,
  t: TFunction,
): string | null => {
  if (value.length === 0) {
    return t(keys.password.required);
  }
  if (value.length < PASS_MIN_LEN) {
    return t(keys.password.length);
  }
  if (!isAsciiOnlyInput(value)) {
    return t(keys.password.ascii);
  }

  return null;
};

export const validateSiteMembersEmail = (
  value: string,
  t: TFunction,
): string | null => {
  if (value.length === 0) {
    return t(keys.email.required);
  }
  if (!isValidEmail(value)) {
    return t(keys.email.format);
  }

  return null;
};
